import "./login.css"

import Header from "../components/header"
import React from "react"
import SignIn from "../components/SignIn"
import SignUp from "../components/SignUp"
import firebase from "gatsby-plugin-firebase"
import { navigate } from "gatsby"

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.SignUp = this.SignUp.bind(this)
    this.SignIn = this.SignIn.bind(this)

    this.state = {
      signIn: false,
      signUp: true,
    }
  }

  componentDidMount() {
    console.log("running")
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // console.log("Logged In " + user.email);
        this.setState({ loggedIn: true })
        navigate("/transapp")
      } else {
        this.setState({ loggedIn: false })
        navigate("/")
      }
    })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  SignUp() {
    this.setState({ signIn: false })
  }

  SignIn() {
    this.setState({ signIn: true })
  }

  render() {
    if (this.state.signIn === true) {
      return (
        <div className="FormPage">
          <Header />

          <div className="FormContainer">
            <h1 className="FormTitle">Get Started</h1>
            <p className="helptext">
              Enter the credentials you used at Sign Up
            </p>
            <SignIn />
            <button id="buttonClass1" onClick={this.SignUp}>
              Don't have an account?
            </button>
          </div>
        </div>
      )
    } else if (this.state.signIn === false) {
      return (
        <div className="FormPage">
          <Header />
          <div className="FormContainer">
            <h1 className="FormTitle">Get Started</h1>
            <p className="helptext">
              If this is your first time here, enter your email address, create
              any password of your choice, and then click "LET'S DO THIS!"
            </p>
            <SignUp />

            <button id="buttonClass1" onClick={this.SignIn}>
              Already Have an Account?
            </button>
          </div>
        </div>
      )
    }
  }
}

export default Login
