import "../pages/login.css"

import ForgotForm from "./forgotform"
import NotificationSystem from "react-notification-system"
import React from "react"
import firebase from "gatsby-plugin-firebase"

class SignIn extends React.Component {
  constructor(props) {
    super(props)

    this.PasswordNotification = this.PasswordNotification.bind(this)

    this.state = {
      _notificationSystem: null,
      email: "",
      password: "",
      loggedIn: false,
      data: "",
    }
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem
  }

  PasswordNotification(event) {
    console.log("Clicked Forgot")
    event.preventDefault()
    this._notificationSystem.addNotification({
      level: "info",
      position: "tc",
      title: "Reset Your Password",
      dismissible: "button",
      children: (
        <div>
          <ForgotForm />
        </div>
      ),
    })
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  SignInUser = e => {
    //console.log("Calling");
    e.preventDefault()

    var email = this.state.email
    var password = this.state.password

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(u => {})
      .catch(error => {
        // console.log(error);

        var errormessage = String(error)
        // console.log(errormessage);

        this._notificationSystem.addNotification({
          message: errormessage,
          level: "error",
          position: "tc",
          title: "Check Your Credentials",
        })
      })

    this.setState({
      email: "",
      password: "",
    })
  }

  render() {
    return (
      <form onSubmit={this.SignInUser}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={this.updateInput}
          value={this.state.email}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={this.updateInput}
          value={this.state.password}
        />
        <NotificationSystem ref="notificationSystem" />
        <button id="buttonClass1" type="submit" onClick={this.SignInUser}>
          Access Your Info
        </button>
        <button id="buttonClass1" onClick={this.PasswordNotification}>
          Forgot Password?
        </button>
      </form>
    )
  }
}

export default SignIn
