import React from "react";
import firebase from "./Firestore";
import NotificationSystem from "react-notification-system";

class ForgotForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);

        this.state = {
            _notificationSystem: null,
            email: ""
        };
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    resetPassword(e) {
        e.preventDefault();

        var emailAddress = this.state.email;

        firebase
            .auth()
            .sendPasswordResetEmail(emailAddress)
            .then(() => {
                // console.log("email sent!");

                this._notificationSystem.addNotification({
                    message: "Please Check Your Email",
                    level: "success",
                    position: "tc",
                    title: "Success"
                });
            })
            .catch(error => {
                // An error happened.
                var errormessage = String(error);
                // console.log(errormessage);

                this._notificationSystem.addNotification({
                    message: errormessage,
                    level: "error",
                    position: "tc",
                    title: "Check Your Credentials"
                });
            });
    }

    render() {
        return (
            <div>
                <form className="Form">
                    <div className="FormGroup">
                        <label className="FormLabel">Email address</label>
                        <input
                            className="FormInput"
                            value={this.state.email}
                            onChange={this.handleChange}
                            type="email"
                            name="email"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                        />
                    </div>

                    <button className="ForgotPassword" onClick={this.resetPassword}>
                        Send Password Reset
          </button>
                    <NotificationSystem ref="notificationSystem" />
                </form>
            </div>
        );
    }
}

export default ForgotForm;
