import "../pages/login.css"

import ForgotForm from "./forgotform"
import NotificationSystem from "react-notification-system"
import React from "react"
import firebase from "gatsby-plugin-firebase"

class SignUp extends React.Component {
  constructor(props) {
    super(props)

    this.PasswordNotification = this.PasswordNotification.bind(this)

    this.state = {
      _notificationSystem: null,
      email: "",
      password: "",
      loggedIn: false,
      data: "",
    }
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem
  }

  PasswordNotification(event) {
    event.preventDefault()
    this._notificationSystem.addNotification({
      level: "info",
      position: "tc",
      title: "Reset Your Password",
      dismissible: "button",
      children: (
        <div>
          <ForgotForm />
        </div>
      ),
    })
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  createUser = e => {
    e.preventDefault()
    const db = firebase.firestore()

    var email = this.state.email
    var password = this.state.password

    var data = {}

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(u => {
        db.collection("Users")
          .doc(`${email}`)
          .set(data)
          .then(function() {
            // console.log("Document successfully written!");
          })
          .catch(function(error) {
            // console.log(error);
            // var errormessage = String(error);
            // console.log(errormessage);
          })
      })
      .catch(error => {
        // console.log(error);
        var errormessage = String(error)
        // console.log(errormessage);

        this._notificationSystem.addNotification({
          message: errormessage,
          level: "error",
          position: "tc",
          title: "Check Your Credentials",
        })
      })

    this.setState({
      email: "",
      password: "",
    })
  }

  render() {
    return (
      <form onSubmit={this.createUser}>
        <NotificationSystem ref="notificationSystem" />

        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={this.updateInput}
          value={this.state.email}
        />

        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={this.updateInput}
          value={this.state.password}
        />

        <button id="buttonClass1" type="submit" onClick={this.createUser}>
          LET'S DO THIS!
        </button>
      </form>
    )
  }
}

export default SignUp
